import '../styles/reset.css';
import '../styles/colors.css';
import '../styles/classes.css';
import '../styles/theme.ts';
import Router from '../containers/Router';
import { Provider } from '../utils/globalContext';
import { useEffect, useState } from 'react';
import { getValidVCSession, initViteConnect } from '../utils/viteConnect';
import { State } from '../utils/types';
import { networkList } from '../utils/constants';

const App = () => {
	const [initialState, setInitialSate] = useState<Partial<State>>()
	useEffect(() => {
		(async () => {
			const vcSession = getValidVCSession();

			const networkType = localStorage.networkType || 'mainnet'

			let vpAddress: undefined | string;
			let activeNetworkIndex: undefined | number;
	
			if (window?.vitePassport) {
				vpAddress = await window.vitePassport.getConnectedAddress();
				if (vpAddress) {
					const activeNetwork = await window.vitePassport.getNetwork();
					activeNetworkIndex = networkList.findIndex((n) => n.rpcUrl === activeNetwork.rpcUrl);
				}
			}
			if (activeNetworkIndex === -1 || isNaN(activeNetworkIndex!)) {
				activeNetworkIndex = networkList.findIndex((n) => n.networkType === networkType)
			}

			const state: Partial<State> = {
				networkType: networkType,
				languageType: localStorage.languageType || 'en',
				vcInstance: vcSession ? initViteConnect(vcSession) : null,
				vpAddress: vpAddress,
				activeAddress: vpAddress || vcSession?.accounts[0],
				activeNetworkIndex: activeNetworkIndex
			};
			setInitialSate(state)
		})()
	}, []);

	return initialState ? (
		<Provider initialState={initialState}>
			<Router />
		</Provider>
	) : null;
};

export default App;