import NewAccount from '../containers/NewAccount';
import Access from '../containers/Access';
import Deposit from '../containers/Deposit';
import TransferMotion from '../containers/TransferMotion';
import TabNavigation from '../containers/TabNavigation';
import { connect } from '../utils/globalContext';
import { useTitle } from '../utils/hooks';
import { State } from '../utils/types';
import AccountInfo from '../containers/AccountInfo';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid';
import Motions from '../containers/Motions';
import { useEffect, useMemo } from 'react';
import { networkList } from '../utils/constants';
import { AccountDisplaySettings } from '../utils/accountDisplaySettings';

type Props = State & {};

const AppHome = ({
	i18n,
	setState,
	accountId,
	activeTab,
	activeNetworkIndex,
	networkType,
	vpAddress,
	vcInstance
}: Props) => {
	useTitle(i18n.app);

	useEffect(() => {
		if(!window.vitePassport)return
		const unsubscribeNetwork = window.vitePassport.on('networkChange', payload => {
			let networkIndex = networkList.findIndex((n) => n.rpcUrl === payload.activeNetwork.rpcUrl)
			if(networkIndex === -1 || isNaN(networkIndex))networkIndex = networkList.findIndex((n) => n.networkType === networkType)
			
			setState({
				activeNetworkIndex: networkIndex
			})
		})
		const unsubscribeAccount = window.vitePassport.on('accountChange', payload => {
			setState({
				vpAddress: payload.activeAddress
			})
		})
		return () => {
			unsubscribeNetwork()
			unsubscribeAccount()
		}
	}, [setState, networkType])

	useEffect(() => {
		if(vpAddress && networkList[activeNetworkIndex]?.networkType !== networkType){
			setState({
				toast: i18n.vpWrongNetwork
			})
		}
	}, [activeNetworkIndex, networkType, vpAddress, setState, i18n.vpWrongNetwork])

	useEffect(() => {
		setState({
			activeAddress: vpAddress || vcInstance?.accounts[0]
		})
	}, [vpAddress, vcInstance, setState])

	const accountDisplaySettings = useMemo(() => new AccountDisplaySettings(), [])
	useEffect(() => {
		setState({
			accountDisplaySettings: accountDisplaySettings
		})
	}, [accountDisplaySettings, setState])

	return (
		<div className="space-y-4 max-w-3xl mx-auto">
			{accountId && (
				<div className="flex justify-end font-bold">
					<p>
						{i18n.account} {accountId}
					</p>
					<button
						className={'h-6 w-6 ml-1 brightness-button font-extrabold transition duration-200 text-red-400'}
						onClick={() => {
							setState({
								accountId: null as unknown as string,
								activeTab: i18n.accessAccount
							});
						}}
					>
						<ArrowRightOnRectangleIcon className="text-inherit" />
					</button>
				</div>
			)}
			{accountId ? (
				<TabNavigation
					tabNames={[
						i18n.account,
						i18n.motions,
						i18n.deposit,
						i18n.transfer
					]}
					defaultTab={i18n.account}
				/>
			) : (
				<TabNavigation tabNames={[
					i18n.accessAccount,
					i18n.createAccount
				]} defaultTab={i18n.accessAccount} />
			)}
			{activeTab === i18n.accessAccount && <Access />}
			{activeTab === i18n.createAccount && <NewAccount />}
			{accountId && activeTab === i18n.account && <AccountInfo />}
			{accountId && activeTab === i18n.motions && <Motions />}
			{accountId && activeTab === i18n.deposit && <Deposit />}
			{accountId && activeTab === i18n.transfer && <TransferMotion />}
		</div>
	);
};

export default connect(AppHome);
