type Props = {
	className?: string;
};

const ViteLogo = ({ className }: Props) => (
	<svg viewBox="0 0 108 60" className={className}>
		<g className="stroke-current" strokeWidth="0" fill="none" fillRule="evenodd">
			<g transform="translate(-375.000000, -21.000000)" className="fill-current	">
				<g transform="translate(375.000000, 21.000000)">
					<g id="header">
						<path
							d="M49.6422106,26.5384615 L56.1772111,43.4716672 L56.2304812,43.4716672 L62.8187518,26.5384615 L63.9923077,26.5384615 L56.8707983,44.423077 L55.5105281,44.423077 L48.4153846,26.5384615 L49.6422106,26.5384615 Z M69.6692308,26.5384615 L69.6692308,44.423077 L68.5153846,44.423077 L68.5153846,26.5384615 L69.6692308,26.5384615 Z M89.1923077,26.5384615 L89.1923077,27.4904267 L82.2817416,27.4904267 L82.2817416,44.423077 L81.0752138,44.423077 L81.0752138,27.4904267 L74.1923077,27.4904267 L74.1923077,26.5384615 L89.1923077,26.5384615 Z M106.847739,26.5384615 L106.847739,27.4909822 L94.9191194,27.4909822 L94.9191194,34.654048 L106.136316,34.654048 L106.136316,35.6060132 L94.9191194,35.6060132 L94.9191194,43.4716672 L106.984615,43.4716672 L106.984615,44.423077 L93.7153846,44.423077 L93.7153846,26.5384615 L106.847739,26.5384615 Z"
							id="Combined-Shape"
						></path>
						<path d="M19.6153846,10.3846154 L17.6605322,60 L0,14.8070785 L19.6153846,10.3846154 Z M63.4615385,4.33431069e-13 L18.4615385,59.423077 L24.2649743,9.2374494 L63.4615385,4.33431069e-13 Z"></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default ViteLogo;
