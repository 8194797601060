import EventEmitter from 'events'
import { useEffect } from 'react'
import { useRefresh } from './hooks'

export type AccountDisplaySettingsData = {
	hidden: boolean
	favorite: boolean
}

export const useAccountDisplaySettings = (accountId:string, settings:AccountDisplaySettings) => {
    const [,refresh] = useRefresh()
    useEffect(() => {
        const listener = (accId:string) => {
            if(accId !== accountId)return

            refresh()
        }
        settings.on('ACCOUNT_DISPLAY_UPDATE', listener)
        return () => {
            settings.off('ACCOUNT_DISPLAY_UPDATE', listener)
        }
    }, [accountId, settings, refresh])

    return settings.get(accountId)
}

export class AccountDisplaySettings extends EventEmitter {
    static get defaultValue():AccountDisplaySettingsData{
        return {
            hidden: false,
            favorite: false
        }
    }
    static get defaultData():Map<string, AccountDisplaySettingsData>{
        return new Map()
    }
    key = 'account_display'
    data: Map<string, AccountDisplaySettingsData>
    constructor(){
        super()
        this.setMaxListeners(Infinity)

        this.data = this.load()
    }

    get(accountId:string){
        return this.data.get(accountId)
            || AccountDisplaySettings.defaultValue
    }

    set(accountId:string, data?:AccountDisplaySettingsData){
        if(!data){
            this.data.delete(accountId)
        }else{
            this.data.set(accountId, data)
        }
        this.emit('ACCOUNT_DISPLAY_UPDATE', accountId)
        this.save()
    }

    private load():Map<string, AccountDisplaySettingsData>{
        const str = localStorage[this.key]
        if(!str)return AccountDisplaySettings.defaultData
        try{
            const raw = JSON.parse(str)
            if(!raw || !Array.isArray(raw))return AccountDisplaySettings.defaultData

            return new Map(raw)
        }catch{
            return AccountDisplaySettings.defaultData
        }
    }

    save(){
        localStorage[this.key] = JSON.stringify(this)
    }

    toJSON(){
        // allow JSON.stringify of this
        return Array.from(this.data.entries())
    }
}