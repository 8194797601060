import React from 'react';

export function ReactFormat({
    children: text,
    params,
    className = 'flex flex-wrap gap-1 justify-center items-center'
}:{
    children: string[],
    params: {
        [key:string]: React.ReactNode
    },
    className?: string
}) {
    if(!text)return null

    const children:React.ReactNode[] = text
        .map((part) => {
            if(part[0] !== '{' || part[part.length-1] !== '}'){
                return <span key={part}>{part}</span>
            }

            const key = part.slice(1, -1)
            return <React.Fragment key={part}>
                {params[key]}
            </React.Fragment>
        })

    return <div className={
        className
    }>
        {children}
    </div>
}