// import React from 'react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
	// React.StrictMode causes double rendering (on dev)
	<StrictMode>
		<App />
	</StrictMode>
);
