import { wallet } from '@vite/vitejs';
import { useEffect, useMemo } from 'react';
import { connect } from '../utils/globalContext';
import { validateInputs } from '../utils/misc';
import { State } from '../utils/types';
import TextInput, { useTextInputRef } from './TextInput';

type Props = State & {
    onAddMember: (member:string)=>void
    confirm?: boolean
    members: string[]
    address?: string
    disabled?: boolean
};

const MemberInputCard = ({
    onAddMember,
    confirm = true,
    i18n,
    members,
    address,
    disabled = false
}: Props) => {
	const memberAddressRef = useTextInputRef();
    useEffect(() => {
        if(confirm || disabled)return
        if(!wallet.isValidAddress(memberAddressRef.value))return
        if(!memberAddressRef.isValid)return

        onAddMember(memberAddressRef.value)
        memberAddressRef.value = ''
    }, [confirm, onAddMember, members, disabled, memberAddressRef, memberAddressRef.value])
    const shouldDisplaySelfAdd = useMemo(() => {
        if(disabled)return false
        if(!address)return false
        if(members.includes(address))return false
        return true
    }, [members, address, disabled])
	return (
        <div className="bg-skin-middleground rounded-md p-8">
            <span className="font-bold">{i18n.addMember}</span>
            <div
                className="w-[calc(100%-1.2rem)] flex flex-col justify-between mt-5"
            >
                <TextInput
                    _ref={memberAddressRef}
                    label={i18n.memberAddress}
                    getIssue={(v) => {
                        if (!wallet.isValidAddress(v)) {
                            return i18n.invalidAddress;
                        }

                        if(members.includes(v)){
                            return i18n.duplicateMember
                        }
                    }}
                    className='w-full'
                    disabled={disabled}
                />
                {confirm && <button
                    className={`${
                        !disabled ? 'bg-skin-button-alt brightness-button' : 'bg-gray-400'
                    } h-8 px-3 rounded-md font-semibold text-skin-button-alt shadow mt-5`}
                    onClick={() => {
                        if (validateInputs([memberAddressRef])) {
                            onAddMember(memberAddressRef.value)
                            memberAddressRef.value = '';
                        }
                    }}
                    disabled={disabled}
                >
                    {i18n.addMember}
                </button>}
                {shouldDisplaySelfAdd && <button
                    className='bg-skin-button-alt brightness-button h-8 px-3 rounded-md font-semibold text-skin-button-alt shadow mt-5'
                    onClick={() => {
                        onAddMember(address!)
                    }}
                >
                    {i18n.addMemberSelf}
                </button>}
            </div>
        </div>
	);
};

export default connect(MemberInputCard);
